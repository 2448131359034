@import './variables.scss';
@import './components.scss';
@import './nprogress.css';
@import './modals.scss';
@import './material.scss';
@import './grapes.scss';
@import "~react-date-range/dist/styles.css";
@import './react-date-range.scss';
@import './scrollbar.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body, button {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}


.dashboard {
  .--page-title {
    margin-bottom: 16px;
  }

  &-chart {
    height: 320px;
    margin-bottom: 16px;
  }

  &-row {
    display: flex;
    flex-direction: row;
  }

  &-row__item {
    flex: 1;
    flex-basis: 50%;
  }

  .performance-chart {
    height: 230px;
  }

  .revenue-table {
    margin-right: 16px;

    .rt-table {
      .rt-tbody .rt-tr-group:nth-child(2n) {
        background: #f2f7f7;
      }
    }
  }

  .usage-chart {
    height: 230px;
  }
}

.members {
  .--page-title {
    margin-bottom: 30px;
  }

  &-table {
    &__avatar {
      margin: 0 6px;
    }
  }
}

.settings {
  &__title {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-content {
    align-items: flex-start;
  }

  &-card {
    margin-right: 25px;
    width: 277px;
    position: relative;
    word-break: break-all;

    &__edit-btn {
      margin-left: 20px;
      margin-top: -60px;
      position: absolute;
      right: 10px;
      color: #757575;
    }
  }
}

.revenue {
  &-table {
    .floating-menu {
      display: none;
      position: absolute;
      right: 30px;
    }

    &__marker {
      margin-right: 10px;
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 5px;
    }

    &__icon {
      cursor: pointer;
    }

    &__icon-edit {
      margin-right: 10px;
    }
  }
}

.notes {
  height: 450px;
  position: relative;
  overflow: auto;
  color: $color-gunMetal;

  .note-container {
    margin: 0 15px 20px 0;

    .note {
      &__avatar {
        margin-right: 5px;
      }

      &__divider {
        margin: 0 8px;
      }

      &__text {
        white-space: pre-wrap;
      }

      &__link {
        margin: 0 0 10px 10px;
        padding: 4px 6px 4px 6px;
        border: 1px solid white;
        border-radius: 5px;
        background-color: #0782c5;
        background-clip: border-box;
        color: white;
      }
    }
  }
}

.dropdown-filter {
  width: 350px !important;
  height: 45px;
  position: relative;
  z-index: 100;

  &-toggle {
    width: 100%;
    height: 100%;
    padding: 13px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $color-gray100;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
  }

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $color-gunMetal;

    .--divider {
      margin: 0 7px;
      font-size: 5px;
    }

    .--toggle {
      font-size: 20px;
      color: #575757;
    }
  }

  .card {
    .date-pickers {
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .--divider {
        margin-bottom: 5px;
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-clear {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .--icon {
          margin-right: 5px;
        }

        .--text {
          font-size: 14px;
          color: $color-orange200;
        }
      }
    }
  }
}

.payment {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $color-blueGray;

  &__back {
    position: absolute;
    top: 50px;
    left: 20px;
  }

  &-card {
    max-width: 380px;
  }

  &-header {
    padding: 25px 0 75px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: $color-blueGray100;

    &__name {
      margin-bottom: 50px !important;
    }

    &__balance {
      color: black;
    }

    &__label {
      text-transform: uppercase;
      color: $color-gray150;
      font-weight: 800;
    }
  }

  &-form {
    padding: 20px;

    &-actions {
      margin: 7px 0 10px !important;
      justify-content: space-between;

      .MuiTypography-root {
        color: $color-skyBlue;
      }

      .--btn {
        cursor: pointer;
      }
    }
  }
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 318px;
  background: #f7f7f7;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  color: #ccc;
  transition: 0.3s;
  outline: none;
  @media screen and (max-height: 480px) {
    height: 250px;
  }
}

.dropzone-sound {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #f7f7f7;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 1rem;
  color: #ccc;
  transition: 0.3s;
  outline: none;
  cursor: pointer;
  @media screen and (max-height: 480px) {
    height: 50px;
  }
}


//Front-desk Common block

.table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ededed;
}

.table {

  &__title,
  &__title.MuiTypography-body1 {
    font-size: .875rem;
    opacity: .3;
    font-weight: bold;
    line-height: 1.2;
  }
}

.slick-dots{
  list-style: none;
}
