th.MuiTableCell-root,
td.MuiTableCell-root {
  &:first-child {
    padding-left: inherit;
  }

  &:last-child {
    padding-right: inherit;
  }
}

th.MuiTableCell-head {
  line-height: 0;
}

span.MuiTableSortLabel-root {
  padding-top: 0;
}
