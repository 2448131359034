#package-modal {
  .balance {
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      color: #a5dc86;
    }
  }

  .due-date {
    display: flex;
    flex-direction: column;

    &__title {
      font-weight: 300;
    }
    &__value {
      font-weight: 600;
    }
  }

  .payment-account {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__icon {
      margin-right: 5px;
      height: 26px;
    }
  }
}

#creadit-card-modal {
  iframe {
    border-style: none;
  }

  .loader {
    position: absolute;
    top: 150px;
    left: 90px;
    z-index: 1;
  }
}

#new-card-modal {
  .form {
    width: 576px;

    &-block {
      width: 280px;
      flex-direction: column;
    }
  }
}

#save-card-modal {
  .--title {
    margin-bottom: 15px;
  }

  .packages {
    margin-bottom: 15px;

    &__item {
      margin-right: 30px;
      padding: 10px 0;
      display: block;
      font-size: 14px;

      label {
        cursor: pointer;
      }
    }
  }
}
