$app-height: 100vh;

// Colors
$color-gunMetal: #4e5563;
$color-lightGray: #c5c9d1;
$color-gray50: #f7f8f8;
$color-gray100: #d9dde5;
$color-gray150: #717b8f;
$color-gray200: #2c2e31;
$color-gray300: #1f2123;
$color-orange100: #e98a43;
$color-orange200: #ef7621;
$color-table-header: #707a8e;
$color-table-border: #ececec;
$color-blueGray: #f7f8f8;
$color-blueGray100: #f7fbff;
$color-skyBlue: #269bd3;

$material-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),
0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

// cinnamon: '#C63D4D',
// gunMetal: '#4e5563',
// blueRoyal: '#445EAE',

// green100: '#25C878',
// green200: '#349252',
// purple: '#AC66BA',
// yellow100: '#E7C46B',
// yellow200: '#E9C843',
// teal: '#25C7C8',
// pink: '#BA6675',
// errorRed: '#cc3300',
// modalBG: 'rgba(44, 46, 49, 0.5)'
