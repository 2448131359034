::-webkit-scrollbar {
  background-color: initial;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.18);
  border-radius: 15px;
  border: 4px solid white;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.38);
}

::-webkit-scrollbar-button {
  display: none;
}

.scrollbar-border-radius {
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-corner {
    border-radius: 4px;
  }
}
